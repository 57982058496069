

<div  class="row">

  <div class="col-sm-6">

    <div class="form-table table-responsive">
      <table class="table table-bordered">


        <tbody>

<tr>
  <td style="padding-left: 0px;width: 0px;"></td>

  <td>
    <div >
      <label style="margin-bottom: 0px;">Gender<span class="text-danger">*</span> </label>
      <select class="custom-select" required [(ngModel)]="add.Gender" name="Gender"
      #Gender="ngModel" style="width:100%" aria-hidden="true"(change)="get_Reference()">
      <option value="Any">
        Any
    </option>
      <option *ngFor="let data of appservice.get_ref('Gender')" value={{data.label}}>
          {{data.label}}
      </option>

  </select>
  </div>
</td>
</tr>

          <tr >
        
<td></td>
        <td >
          <div >
            <div style="width: 50%;display: inline-flex;">

            <label  style="margin-bottom: 0px; margin-top: 10px;">Minimun Age <span class="text-danger">*</span></label>
            </div>
            <div style="width: 50%;display: inline-flex;">

              <label style="margin-bottom: 0px; margin-top: 10px;">Maximum Age<span class="text-danger">*</span></label>
              </div>
            <div style="width: 50%;display: inline-flex;">
              <div style="width: 50%;">
  
                <input class="form-control"required type="number" value="0" [(ngModel)]="add.Day_From" name="Day_From" #Day_From="ngModel">
  
                  </div>
  
                  <div style="width: 50%;">
                    <input class="form-control"readonly placeholder="Days">
  
                  </div>
          </div>
          <div style="width: 50%;display: inline-flex;">
            <div style="width: 50%;">
      
              <input class="form-control"required type="number" value="0" [(ngModel)]="add.Day_To" name="Day_To" #Day_To="ngModel">
      
                </div>
      
                <div style="width: 50%;">
                  <input class="form-control"readonly placeholder="Days">
      
                </div>
        </div>
        </div>
        <div >
          <div style="width: 50%;display: inline-flex;">
            <div style="width: 50%;">

              <input class="form-control"required type="number" value="0" [(ngModel)]="add.Age_From" name="Age_From" #Age_From="ngModel"(keyup)="get_Year()">

                </div>

                <div style="width: 50%;">
                  <input class="form-control"readonly placeholder="Years">

                </div>
        </div>
        <div style="width: 50%;display: inline-flex;">
          <div style="width: 50%;">
      
            <input class="form-control"required type="number" value="0" [(ngModel)]="add.Age_To" name="Age_To" #Age_To="ngModel"(keyup)="get_Year()">
      
              </div>
      
              <div style="width: 50%;">
                <input class="form-control"readonly placeholder="Years">
      
              </div>
      </div>
      </div>



</td>
        </tr>
 
   
        <tr  *ngIf="appservice.Test_Row_.Input_type=='Numeric'">
        
          <td></td>

      <td>
        <div >

          <div style="width: 50%;display: inline-flex;">

            <label style="margin-bottom: 0px; margin-top: 10px;">Lower value<span class="text-danger">*</span></label>
          </div>
            <div style="width: 50%;display: inline-flex;">

              <label style="margin-bottom: 0px; margin-top: 10px;">Upper value<span class="text-danger">*</span></label>
            </div>


            <div style="width: 100%;display: inline-flex;">
              <div style="width: 50%;">
  
                <input class="form-control"required type="number" [(ngModel)]="add.Min_Value" name="Min_Value" #Min_Value="ngModel"(keyup)="get_Reference()">
  
                  </div>
  
                  <div style="width: 50%;">

                    <input class="form-control"required type="number" [(ngModel)]="add.Max_Value" name="Max_Value" #Max_Value="ngModel"(keyup)="get_Reference()">
      
                      </div>
          </div>

      


        
      </div>
  


</td>


      </tr>
    
      <tr *ngIf="appservice.Test_Row_.Input_type=='Text'">
        <td></td>

        <td>
          <div >
            <label style="margin-bottom: 0px; margin-top: 10px;">Text<span class="text-danger">*</span></label>
            <input class="form-control"required type="text" [(ngModel)]="add.Text_" name="Text_" #Text_="ngModel"(keyup)="get_Reference()">
      
        </div>
      </td>
      </tr>
      <tr>
        <td></td>
        <td>
          <div >
            <label style="margin-bottom: 0px; margin-top: 10px;">Normal Value<span class="text-danger">*</span></label>
            <textarea rows="4" class="form-control"required type="text" [(ngModel)]="add.Reference_Value" name="Reference_Value" #Reference_Value="ngModel"></textarea>
      
        </div>
      </td>
      </tr>
    
  
  
          
       <tr *ngIf="msg!=''">
         <td>
        
         </td>
         <td>   
         <label style="color: red;text-align: center;">{{msg}}</label>
         </td>
       </tr>
  
  
  
  
          </tbody>

      </table>



    </div>
  </div>
  <div class="col-sm-6 ">

    <div class="form-table table-responsive">
      <table class="table table-bordered mhide">

        <tbody>
          <tr class="hidden">
            <td>ID <span class="text-danger">*</span></td>
            <td>
              <input class="form-control" type="text" [(ngModel)]="add.ID" name="ID" #ID="ngModel" required>
            </td>
          </tr>

          <tr >
            <td  style="padding-left: 8px;width: 150px;" >Category</td>
            <td  style="text-align: center;">

              <input class="form-control" type="text"readonly [(ngModel)]="appservice.Test_Row_.Category_" name="Category_" #Category_="ngModel">

           





            </td>

        </tr>
        
        <tr >
          <td  style="padding-left: 8px;width: 150px;" >Test Name</td>
          <td  style="text-align: center;">

            <input class="form-control" type="text"readonly [(ngModel)]=" appservice.Test_Row_.Test_Name" name="Test_Name" #Test_Name="ngModel">

         





          </td>

      </tr>
      
      <tr >
        <td  style="padding-left: 8px;width: 150px;" >Input type</td>
        <td  style="text-align: center;">

          <input class="form-control" type="text"readonly [(ngModel)]="appservice.Test_Row_.Input_type" name="Input_type" #Input_type="ngModel">

       





        </td>

    </tr>

    
  
       


    <tr >
      <td  style="padding-left: 8px;width: 150px;" >Unit</td>
      <td  style="text-align: center;">

        <input class="form-control" type="text"readonly [(ngModel)]="appservice.Test_Row_.Unit" name="Unit" #Unit="ngModel">

     





      </td>

  </tr>
        
     




        </tbody>
     

      </table>

      <div class="invoice-btn-box text-right " style="padding-bottom:20px">
  
        <button class="btn btn-info rounded-pil" type="submit" [disabled]="btndisable" (click)="addData()"> 
          
          
          <span *ngIf="btndisable==true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>


          <i  *ngIf="btndisable==false" class='bx bx-paper-plane'></i> {{appservice.Button_Name}} </button> &nbsp;&nbsp;
         
    </div>

    </div>
  </div>
</div>
<label style="        width: 100%;
text-align: left;
padding-left: 24px;" >{{appservice.Old_Reference_Value}}</label>
<br>
   <p-table responsiveLayout="scroll" #dt [globalFilterFields]="['Gender','Age_From','Text_','Input_Type']"
  class="table table-striped custom-table" [value]="appservice.Test_Parameter_Rows">
  <ng-template pTemplate="header">
    <tr>



      <th>#</th>
      <th [pSortableColumn]="'Gender'"> Gender    </th>

   
      <th [pSortableColumn]="'Age_From'"class="mhide"> Age 

      </th>

      <th [pSortableColumn]="'Reference_Value'" >Reference Value</th>

      <th></th>
    </tr>

  </ng-template>
  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
    <tr>

      <td>{{rowIndex+1}}  </td>
      <td>{{rowData.Gender}}
  

      </td>
      <td class="mhide"  >
        <span *ngIf="rowData.Age_To==0" >{{rowData.Day_From}}D - {{rowData.Day_To}}D<br></span>
        <span class="mhide" *ngIf="rowData.Age_To!=0">{{rowData.Age_From}}Yrs -{{rowData.Age_To}}Yrs </span>
      </td >
  

    
      <td  >{{rowData.Reference_Value}}</td>

      






     
      <td class="text-right" style="white-space: pre">
        <a href="javascript:" (click)="onEdit(rowData)" style="font-size: 18px;padding: 5px" title="Edit"><i class="bx bxs-pencil"></i>
        </a>
        <a title="Delete" style="font-size: 18px;padding: 5px;color: firebrick"
          (click)="onDelete(rowData.ID)"><i class="bx bxs-trash"></i>
        </a>
      </td>
    </tr>
  </ng-template>
</p-table>
OLD Reference:{{this.appservice.Test_Row_.Default_Result}}