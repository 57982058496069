

<div class="row">
  <div class="col-md-12 nopad">
     <div class="col-md-12 nopad mobile_hide">


        <div class="w_79" style="position: fixed;z-index: 99; ">
          <div class="tabs-header border-bottom">
            <ul>

              <li  > <a  style="display: inline-flex;">  Item Details</a></li>
           

            </ul>
          </div>


          <div class="right-corner">

            <div class="dropdown" style="display: inline-flex;">

              <a class="dropdown-toggle   addbtn1" (click)="addReset()" data-toggle="tooltip" data-placement="bottom"
              title="Add New">

              <i class='bx bx-list-plus'></i>
              
            </a>

            <a class="dropdown-toggle  serch_icon   mshow"  data-toggle="tooltip" data-placement="bottom"
            title="search" >
           
              <i style="    font-size: 20px;" class='bx bx-search'></i> 
            </a>



            <a  style="padding-left: 10px;padding-right: 10px;" class="dropdown-toggle " (click)="appservice.get_Item_Master()" data-toggle="tooltip" data-placement="bottom"
            title="Refresh">

            <i class='bx bx-rotate-left'></i>
          </a>

              <button class="dropdown-toggle" type="button" data-toggle="dropdown" title="more" aria-haspopup="true"
                aria-expanded="false">
                <i class='bx bx-filter'></i>
                
              </button>

              <div class="dropdown-menu" style="width: 325px;">
                <div style="background-color: teal; color: white; width: 100%; ">
                  &nbsp;&nbsp;Search</div>
                <table>


                  <tr>
                    <td style="width: 80px;">Order by </td>
                    <td>

                      <select class="area form-control form-control-sm" [(ngModel)]="appservice.Item_Orderby_Name"
                        name="Item_Orderby_Name" #Item_Orderby_Name="ngModel" style="width:100%" aria-hidden="true">

                        <option value="Display_order">
                          Display Order
                        </option>
                        <option value="Item_Name">
                          Name
                        </option>
                        <option value="CreatedDate desc">
                          Created Date
                        </option>

                      </select>

                    </td>
                  </tr>


                  



                 

                  <tr>
                    <td> </td>
                    <td><button type="button" (click)="appservice.get_Item_Master()"
                        class="btn btn-info btn-sm">Search</button></td>
                  </tr>

                </table>


                <div  class="search_Footer border-top" style="color: green;"> 
                    <div (click)="export_excel(dt)"  class="buttons">
                      <span><li class="bx bx-download"></li></span>
                     <span> Export Excel</span>
                     
                    </div>

                   
                    <div (click)="export_pdf(dt)"  class="buttons " style="color: red;">
                      <span><li class="bx bx-download"></li></span>
                     <span> Export PDF</span>
                     
                    </div>


                </div>
               

              </div>

            </div>

          </div>

        </div>
      </div>
    

  
      <div class="col-md-12 nopad">


        <div class="spin_block" *ngIf="appservice.isload">

          <div class="d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

        </div>


        <div class="m_2 res_table">


          <div class="search-bar_Ext mhide  show_bar " style="right: 110px;">
        
            <li  class="bx bx-left-arrow-alt mshow  search_back"></li>

                <input  type="text"  (input)="dt.filterGlobal($event.target.value, 'contains')" 
                class="search-form-control text"
                placeholder="Search...">
    
          </div>
         
           <p-table responsiveLayout="scroll" #dt  [globalFilterFields]="['Item_Name','Category']"  
           class="table table-striped custom-table" [value]="appservice.Item_Master_Rows" >
            <ng-template pTemplate="header">
              <tr>

            
               
                <th>#</th>
                <th [pSortableColumn]="'Item_Name'" >Name </th>
                <th [pSortableColumn]="'Description'" class="mhide">Description</th>
                
                
                <th [pSortableColumn]="'Bag_Qty'" >Bag Qty</th>
                <th [pSortableColumn]="'Rate'" class="mhide">Rate</th>
               
                <th  [pSortableColumn]="'Category'"class="mhide">Category</th>

                <th [pSortableColumn]="'Display_order'"class="mhide">Disp Order</th>
                <th *ngIf="appservice.img_visible" width=90></th>
                <th ></th>
              </tr>

            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
              <tr>
               
                <td>{{rowIndex+1}}</td>
                <td>{{rowData.Item_Name}}
                <span class="mshow text-muted">
                  {{rowData.Description}}
                </span>
                <span class="mshow text-muted">
                 Rate : {{rowData.Rate}}
                </span>  
                </td>
                <td class="mhide">{{rowData.Description}}</td>
                
                <td >{{rowData.Bag_Qty}}</td>
                
                <td class="mhide">{{rowData.Rate}}</td>
               
                
                <td class="mhide">{{rowData.Category}}</td>
                <td class="mhide">{{rowData.Display_order}}</td>

                <td *ngIf="appservice.img_visible" > <img height="50" src="{{appservice.Server_URL}}/image/C{{appservice.Company}}/I{{rowData.ID}}.png?{{appservice.Today_Date}}"> </td>
                <td class="text-right" style="white-space: pre">
                  <a href="javascript:" style="font-size: 18px;padding: 5px" title="Edit" (click)="onEdit(rowData)"><i
                      class="bx bxs-pencil"></i>
                  </a>
                  <a title="Delete" style="font-size: 18px;padding: 5px;color: firebrick"
                    (click)="onDelete(rowData.ID)"><i class="bx bxs-trash"></i>
                  </a>
                </td>
              </tr>
            </ng-template>
          </p-table>


        </div>
      </div>
    

   
      <div class="col-md-12 nopad">

        <div class="w_79" style="  bottom: 0;
            font-weight: 700;
            color: #333333;
            background-color: #f4f4f4;
            padding: 0.571em 0.857em;
            position: fixed;
            bottom: 0;
            
            border: 1px solid #c8c8c8;">
            
            
            <table style="width: 100%;"><tr>
              <td style="color: darkslategray;">Total Rows : {{appservice.Item_Master_Rows.length}} </td>
              <td style="text-align: right;     color: crimson;"></td>
          </tr></table>

            </div>
      </div>
   
  </div>
</div>



