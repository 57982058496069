<ng-container >
  <app-sidebar ></app-sidebar>
  <!-- Start Main Content Wrapper Area -->
  <div  class="main-content d-flex flex-column {{appservice.mani_class}}">
    <app-header ></app-header>
      <router-outlet  ></router-outlet>
     
      <div class="flex-grow-1"></div>
    <!-- <app-footer></app-footer> -->
  </div>
  

  

  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

  
</ng-container>


<div class="modal fade basicModalLG" id="small_Module"  data-backdrop="static" data-keyboard="false"  role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Item Master</h5>
              <button type="button" class="close"  (click)="appservice.Close_Modal()">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body" style="padding-top: 0px;
          padding-bottom: 0px;">
           
             <app-add-item1></app-add-item1>
          </div>

          <div class="modal-footer hidden">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Save changes</button>
          </div>
      </div>
  </div>
</div>